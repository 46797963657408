<script lang="ts" setup>
import {mdiArrowRight} from "@mdi/js";
import {TypedRouteLocationRawFromName} from "@typed-router";

import {type RoutesNamesList} from "#build/typed-router/__routes";

const emit = defineEmits<(e: "closeMainMenu", value: boolean) => void>();

type Props = {
  slug?: string;
  title?: string;
  excerpt?: string;
  to?: TypedRouteLocationRawFromName<RoutesNamesList, string> | string;
  icon?: string;
  order?: number;
  flip?: boolean;
  href?: string;
  externalLink?: boolean;
  noPadding?: boolean;
  cardColor?: string;
  denseMobile?: boolean;
  headingLevel?: number;
};
const props = withDefaults(defineProps<Props>(), {
  slug: undefined,
  title: '',
  excerpt: undefined,
  to: undefined,
  icon: undefined,
  order: 2,
  cardColor: undefined,
  headingLevel: 3,
});

const orderMod = computed(() => ((props.order || 0) + 1) % 2);

const reverse = computed(() => {
  if (props.flip) return true;
  return !!orderMod.value;
});

const backgroundColor = props.cardColor
    ? props.cardColor.startsWith("var(--")
        ? props.cardColor // Direkte Verwendung der CSS-Variable
        : "#" + props.cardColor // Annahme, dass es sich um einen Hex-Wert handelt
    : `var(--card-color-undefined)`; // Standardfall, falls cardColor nicht gesetzt ist

const iconPath = computed(() => {
  if (props.icon) {
    return props.icon;
  }
  if (props.slug) {
    return `/images/${props.slug}-logo.svg`; // Default icon path using slug
  }
  return null;
});
</script>

<template>
  <v-card
      :to="to"
      :href="href"
      :target="externalLink ? '_blank' : '_self'"
      class="background transparent-1"
      style="--v-hover-opacity: 0"
  >
    <v-row
        no-gutters
        :class="{ 'flex-row-reverse': reverse, 'pb-3': !denseMobile }"
        class="card__wrapper pb-sm-0"
    >
      <v-col class="card__content d-sm-flex flex-column justify-center">
        <div
            class="card__header--mobile-grid"
            :class="{ 'reverse reverse--mobile-img': reverse }"
        >
          <div class="title__area">
            <slot name="title">
              <div class="card__title px-5 px-sm-2">
                <component
                    :is="(props.headingLevel < 1 ||  props.headingLevel > 5) ? 'div' : 'h' + props.headingLevel"
                    class="text-h3 pa-0"
                >
                  <span v-html="title" />
                </component>
                <MDCSlot v-if="denseMobile">
                  <span v-html="excerpt" />
                </MDCSlot>
              </div>
            </slot>
          </div>
          <div
              class="image__area py-0 px-0 d-sm-none"
              :class="{ 'mr-auto': reverse, 'ml-auto': !reverse }"
          >
            <v-sheet
                :width="denseMobile ? '100px' : '80px'"
                :color="backgroundColor"
                class="d-flex justify-center hw-card__image--mobile align-center"
            >
              <slot name="icon">
                <v-responsive
                    aspect-ratio="1"
                    class="d-flex justify-center"
                    content-class="d-flex justify-center align-center image--min-size"
                >
                  <hw-image
                      v-if="iconPath"
                      :src="iconPath"
                      object-fit="contain"
                      style="max-width: 70% !important; height: 100%"
                  />
                </v-responsive>
              </slot>
            </v-sheet>
          </div>
        </div>
        <div>
          <div class="mx-4 mx-sm-2 mt-4" v-if="!denseMobile">
            <div class="d-flex align-end card__content-text">
              <div>
                <MDCSlot><span v-html="excerpt" /></MDCSlot>
              </div>
              <v-icon
                  v-if="to || href"
                  :active="false"
                  class="ml-auto mr-4"
                  :class="{
                  'icon--external-link': externalLink,
                }"
              >
                {{ mdiArrowRight }}
              </v-icon>
            </div>
            <div v-if="href" class="card__underline" />
          </div>
        </div>
      </v-col>

      <v-col cols="3" class="d-none d-sm-block">
        <v-sheet
            :color="backgroundColor"
            class="d-flex justify-center h-100 hw-card__image align-center"
            :style="{ padding: noPadding ? '' : '1rem' }"
        >
          <slot name="icon">
            <hw-image
                v-if="iconPath"
                :src="iconPath"
                height="144px"
                object-fit="contain"
                class="hw-image--max-width-display-sm"
            />
          </slot>
        </v-sheet>
      </v-col>
    </v-row>
  </v-card>
</template>


<style lang="scss">
@use "~/../_common/assets/scss/mixins";
@use "sass:math";

// fixme: find better way to enable overwrite -> there are more util classes

@include mixins.media-query("xs") {
  .text-h3 {
    padding-top: 0;
    padding-bottom: 0.5rem;
  }
}

.card__wrapper {
  @include mixins.media-query("sm-and-up") {
    min-height: 184px;
  }
}

.hw-image--max-width-display-sm {
  max-width: 80% !important;
}

.card__title {
  overflow: hidden;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 3;
  padding-bottom: 0;
  display: table-cell;
  vertical-align: middle;
}

.card__content {
  color: rgb(var(--v-theme-on-background));
  @include mixins.media-query("sm-and-up") {
    //padding-top: 0.5rem !important;
    //padding-left: var(--spacer) !important;
    //padding-bottom: 1rem !important;

    padding: 1.5rem !important;
  }

  @include mixins.media-query("md-and-up") {
    padding: 2rem 3.125rem !important;
  }

  @include mixins.media-query("lg-and-up") {
    // padding: 1rem var(--spacer) 2rem !important;

    padding: 3.125rem 4.5rem !important;
  }

  .image--min-size {
    object-fit: contain;
    min-width: 70px;
  }
}

.card__content-text {
  @include mixins.media-query("xs") {
    padding-bottom: 0.5rem;
  }
}

a .card__underline::before {
  background-color: rgb(var(--v-theme-primary));
}
</style>
